@import 'app';
@import '../styles/variable.scss';

$mini-sidebar-width: 90px;
/*******************
Main sidebar
******************/

.mat-sidenav {
    width: 240px;
    padding-top: 0px;
}

#snav {
    border-right: unset !important;
    background-color: $neutral50;

    .mat-drawer-inner-container {
        overflow: hidden;
    }

    .mat-nav-list .mat-list-item {
        height: auto;
        color: #ffffff;

        &:hover {
            background: none;
        }

        .mat-list-item-content {
            display: block;
            padding: 0px 0px;

            &:hover {
                background: none;

                > a {
                    color: $neutral600;
                }
            }
        }

        a {
            height: 45px;
            padding: 15px 10px;
            margin-bottom: 10px;
            align-items: center;
            display: block;
            font-size: 14px;
            white-space: nowrap;
            color: $sidebar-text;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 400;
            line-height: 14px;
            color: $neutral600;
            font-family: 'Gotham Light';
            font-weight: 400;

            img {
                width: 20px;
                height: 20px;
                color: #fff;
            }

            mat-icon:not(.dd-icon) {
                margin-right: 8px;
            }

            mat-icon {
                color: $neutral300;
            }

            .faIcon {
                min-width: 40px;
            }

            .dd-icon {
                font-size: 16px;
                width: 16px;
                transition: 0.2s ease-in;
                margin: 5px 0 5px 5px;
                height: 16px;
            }
        }

        .escrow-divider {
            width: 100%;
            height: 1px;
            background: $neutral100;
            margin: 24px 0;
        }

        &.hovered:not(.selected) > .mat-list-item-content > a {
            border: 1px solid $neutral300;
            border-radius: $radius;
        }

        &.preselect:not(.selected) > .mat-list-item-content > a {
            border: 1px solid $neutral300;
            border-radius: $radius;
        }

        &.selected > .mat-list-item-content > a {
            background: $primary300;
            border-radius: $radius;
            color: #fff;

            mat-icon {
                color: #fff;
            }
        }

        &.selected .sub-item .mat-list-item-content a.selected {
            background: transparent;
            color: $inverse;
            font-weight: 500;
        }
    }

    .app-sidebar > .mat-nav-list > .mat-list-item > .mat-list-item-content {
        margin: 0px 15px;
        .mat-list-item-ripple {
            border-radius: 200px;
        }
    }

    .mat-nav-list .sub-item {
        display: none;
        margin-top: -15px;

        a {
            padding-left: 40px;
            height: 50px;
            margin-bottom: 0px;
        }

        .child-sub-item {
            display: none;
            margin-top: -15px;

            a {
                padding: 0 0 0 55px;
                height: 50px;
                margin-bottom: 0px;
            }
        }
    }

    .saperator {
        cursor: text;
        font-size: 13px;
        margin-bottom: 15px;
        margin-top: 25px;
        font-weight: 500;
        white-space: nowrap;
    }

    .selected {
        .sub-item {
            display: block;

            .selected + {
                .child-sub-item {
                    display: block;
                }
            }
        }
    }
}

/*******************
use profile section
******************/

.user-profile2 {
    cursor: pointer;
    padding: 0px 20px;
    @media (min-height: 680px) {
        position: fixed;
    }
    bottom: 0;
    width: 240px;
    // margin-bottom: 28px;

    img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }

    .user-name {
        font-family: 'Gotham Light';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $neutral0;
    }

    .user-account {
        display: flex;
        padding: 2px 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid $primary200;
        background: $primary200;
        font-family: 'Gotham Light';
        font-weight: 400;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        span {
            color: #fff;
        }
    }

    .account-select {
        display: flex;
        padding: 10px 8px 10px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
            background-color: $neutral100;
        }
    }

    .account-selected {
        display: flex;
        padding: 10px 8px 10px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        cursor: pointer;
        background-color: $primary300;
        color: $neutral0;
        
        &:hover {
            background-color: $neutral100;
        }
    }

    .account-icon {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        border-radius: 200px;
        border: 1px solid $primary200;
    }

    mat-icon {
        color: $sidebar-icons;
    }
}

.user-profile-menu-no-photo {
    height: 27px;
    width: 27px;
    border-radius: 100%;
    cursor: pointer;
    background-color: $primary300;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    display: flex;
    align-items: center;
    border: 1px solid $primary200;

    span {
        font-family: 'Gotham Light';
    }
}

.user-profile-no-photo {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    cursor: pointer;
    background-color: $primary300;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-profile-menu {
    height: 48px;
    width: 48px;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;

    img {
        max-width: 100%;
    }
}

.user-profile2-mini {
    @extend .user-profile2;
    width: $mini-sidebar-width;

    .user-name {
        visibility: hidden;
        display: none;
    }

    .user-account {
        visibility: hidden;
        display: none;
    }
}

.user-profile {
    position: relative;
    background-size: cover;

    .profile-img {
        width: 50px;
        margin-left: 30px;
        padding: 31px 0;
        border-radius: 100%;

        &::before {
            -webkit-animation: 2.5s blow 0s linear infinite;
            animation: 2.5s blow 0s linear infinite;
            position: absolute;
            content: '';
            width: 50px;
            height: 50px;
            top: 31px;
            margin: 0 auto;
            border-radius: 50%;
            z-index: 0;
        }

        @-webkit-keyframes blow {
            0% {
                box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
                opacity: 1;
                -webkit-transform: scale3d(1, 1, 0.5);
                transform: scale3d(1, 1, 0.5);
            }

            50% {
                box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
                opacity: 1;
                -webkit-transform: scale3d(1, 1, 0.5);
                transform: scale3d(1, 1, 0.5);
            }

            100% {
                box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
                opacity: 0;
                -webkit-transform: scale3d(1, 1, 0.5);
                transform: scale3d(1, 1, 0.5);
            }
        }

        @keyframes blow {
            0% {
                box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
                opacity: 1;
                -webkit-transform: scale3d(1, 1, 0.5);
                transform: scale3d(1, 1, 0.5);
            }

            50% {
                box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
                opacity: 1;
                -webkit-transform: scale3d(1, 1, 0.5);
                transform: scale3d(1, 1, 0.5);
            }

            100% {
                box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
                opacity: 0;
                -webkit-transform: scale3d(1, 1, 0.5);
                transform: scale3d(1, 1, 0.5);
            }
        }

        img {
            width: 100%;
            border-radius: 100%;
        }
    }

    .profile-text {
        padding: 5px 0px;
        position: relative;
        cursor: pointer;
        white-space: nowrap;

        > a {
            color: $white !important;
            width: 100%;
            padding: 6px 30px;
            background: rgba(0, 0, 0, 0.5);
            display: block;
            white-space: nowrap;

            &:after {
                position: absolute;
                right: 20px;
                top: 20px;
            }
        }
    }
}

.mat-menu-panel {
    border-radius: 0 !important;
}

.subnav-header {
    display: flex;
    padding: 0 16px;
    height: 48px;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    color: $neutral600;
    font-weight: 700;
    cursor: pointer;

    .subnav-header-icon {
        font-size: 16px;
        line-height: 16px;
        color: $neutral400;
        margin-right: 12px;
    }
}

.subnav-icon {
    font-size: 12px;
    color: $neutral300;
}

.accounts {
    position: relative;
    width: 100%;
    padding: 12px 16px;
    background-color: $primary300;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    z-index: 999999999999;

    mat-icon {
        color: $neutral75 !important;
    }
}

.accounts-pop {
    overflow: auto;
    max-height: 90vh;
    background-color: #fff;
    position: absolute;
    right: 50%;
    bottom: 100%;
    transform: translate(50%, 0.75rem);
    border-radius: 8px;
    border: 1px solid #fff;
    display: flex;
    flex-direction: column;
    gap: 4px;
    display: flex;
    width: 192px;
    padding: 8px;
    flex-direction: column;
    z-index: 999999999999;
    font-size: 12px;
    @media (min-width: 960px) and (max-width: 1600px) {
        bottom: 20%;
    }
}

.accounts-pop:hover + p {
    p {
        width: 100%;
        border-radius: 8px;
        background-color: yellow;
    }
}

/*==============================================================
 Small Desktop & above all (1024px)
 ============================================================== */

@media (min-width: 1024px) {
    #snav.mat-sidenav ~ .mat-drawer-content {
        margin-left: 240px !important;
        padding-left: 60px !important;
        padding-right: 16px;
    }

    #snav.mat-sidenav ~ .mat-drawer-content.minitoggle {
        margin-left: 0px !important;
    }

    .minisidebar #snav {
        .mat-drawer-backdrop {
            display: none;
        }

        &.mat-sidenav ~ .mat-drawer-content {
            margin-left: $mini-sidebar-width !important;
        }

        &.mat-sidenav {
            -webkit-transition:
                width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1),
                transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
            transition:
                width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1),
                transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;

            width: $mini-sidebar-width;

            .sub-menu,
            .selected .sub-item {
                display: none;
                visibility: hidden;
            }

            .user-profile {
                .profile-img {
                    margin-left: 15px;
                }

                .profile-text {
                    visibility: hidden;
                }
            }

            .saperator span {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 40px;
                display: block;
            }

            .mat-list-item > .mat-list-item-content > a {
                padding-right: 0;
                padding-left: 0;
                text-align: center;
            }

            .mat-list-item > .mat-list-item-content > a > span:not(.menu-badge),
            .mat-list-item > .mat-list-item-content > a > .menu-caret {
                display: none;
            }

            .mat-list-item > .mat-list-item-content > a > span.menu-badge {
                position: absolute;
                top: 10px;
                right: 16px;
            }

            .mat-list-item > .mat-list-item-content > a > .material-icons {
                width: $mini-sidebar-width !important;
                padding: 0;
                margin: 0;
            }
        }
    }

    [dir='rtl'] {
        app-sidebar {
            display: block;
            overflow: hidden;
        }

        .minisidebar #snav.mat-sidenav ~ .mat-drawer-content {
            margin-left: 0px !important;
            margin-right: $mini-sidebar-width !important;
        }
    }
}

.sidebar-closed #snav {
    &.mat-sidenav ~ .mat-drawer-content {
        margin-left: 0px !important;
    }
}

@media (min-width: 1024px) {
    .minisidebar #snav.mat-sidenav ~ .minitoggle.mat-drawer-content {
        margin-left: 0 !important;
    }
}

/*==============================================================
                          NEW SCCS
 ============================================================== */

.company-logo {
    padding: 35px 41px 16px 41px;

    .close-menu {
        position: absolute;
        right: 24px;
        cursor: pointer;
    }
}

.company-logo-icon {
    width: 40px !important;
    height: 40px !important;
    display: block;
}

.close-expand {
    padding: 5px 20px;
    cursor: pointer;

    mat-icon {
        color: $sidebar-icons;
    }
}

.close-expand-mini {
    @extend .close-expand;
    padding: 5px 25px;
}

/*==============================================================
                          NEW SCCS
 ============================================================== */
.mat-sub-menu {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: unset !important;
    height: 100vh !important;
    max-height: 100vh !important;
    min-width: 280px !important;
    max-width: 380px !important;
    background: $neutral50;
    font-family: 'Gotham Light';

    div {
        font-family: 'Gotham Light';
        .title-sub-menu {
            color: $primary300;
        }
    }

    .title {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        color: $neutral600;
        font-family: 'Gotham Light';
    }

    .icon {
        height: 20px;
        font-size: 16px;
        line-height: 20px;
        color: $primary600;
        margin-right: 10px;
    }

    .perfil {
        color: $primary300;
        padding-left: 16px;
        cursor: pointer;
        margin-bottom: 24px;

        i {
            margin-right: 8px;
        }
    }

    .exit {
        color: #ec0505;
        padding-left: 16px;
        cursor: pointer;

        i {
            margin-right: 8px;
        }
    }

    .sub-title {
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        padding: 0 16px;

        i {
            line-height: 16px;
            color: #ffffff;
        }
    }

    .mat-menu-content .mat-menu-item {
        background: none;

        &.selected {
            background: $primary300;
            border-radius: $radius;
            color: $neutral0;

            mat-icon {
                color: $sidebar-text;
            }
        }

        &.hovered:not(.selected) {
            border: 1px solid $neutral300;
            border-radius: $radius;
        }
    }

    .mat-expansion-panel-header {
        background: none !important;
    }

    .mat-expansion-panel-header.mat-expanded {
        height: 48px !important;
        margin-bottom: 0px;
    }

    .mat-expansion-panel-body {
        padding: 0px 3px;
    }
}

.mat-sub-menu-profile {
    margin-left: 36px;
}

.app-menu-toggle {
    .mat-menu-trigger {
        position: absolute;
        top: 0;
        right: 0;
    }
}

// MENU PERFIL
.menu-perfil {
    button {
        font-size: 14px;
        line-height: 20px;

        i {
            font-size: 24px;
            line-height: 1;
        }
    }

    button.exit {
        color: #ec0505;
    }
}

.overflow-account {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9998;
}

.managers {
    margin: 0px 0px 10px 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
    color: white !important;

    svg {
        border: 1px solid white !important;
        border-radius: 50%;
        padding: 5px;
        height: 27px;
        width: 27px;
    }

    .manager-title {
        font-size: 12px;
        font-weight: 600;
    }

    .manager-info {
        @extend .manager-title;
        font-weight: normal;
        color: $neutral75 !important;
    }

    mat-icon {
        color: $neutral75 !important;
    }

    .overflow-text {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    a {
        color: unset !important;
    }
}

.language-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    padding: 10px;
    margin: 5px;
    width: 20%;
    box-sizing: border-box;
    border-radius: 200px;
  }
  
  .language-button .faIcon {
    display: flex;
    justify-content: center;
  }
  
  .language-button:hover:not(.language-button-selected) {
    border: 1px solid $primary300;
    margin: 4px 5px;
  }
  
  .language-button-selected {
    border: none;
    background-color: $primary300;
  }
  
