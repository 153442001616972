@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@import '~@fortawesome/fontawesome-free/css/all.css';
// @import url("https://fonts.cdnfonts.com/css/gotham-6");
@import './assets/styles/variable.scss';

$bodyFont: 'Lato', normal;
$headlineFont: 'Lato', normal;

html,
body {
    height: 100%;
    background-color: $neutral50;
}

body {
    font-family: 'Lato' !important;
}

.mat-drawer-content {
    background-color: #ffffff;
}

.main-title {
    font-size: 36px;
    font-weight: 900;
    margin: 16px 0px;
}

.button-basic {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 8px 18px;
    border: 1px solid $primary200;
    border-radius: 32px;
    min-width: 140px;
    gap: 10px;
    cursor: pointer;
    font-family: 'Gotham';
    height: 40px;

    // vamos utilizar o do btn-primary para aplicar nessa classe
    background-color: $primary200;
    color: #fff;
}

.button-simple {
    display: flex;
    height: 40px;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2000px;
    border: 1px solid #ccc;
    color: $primary200;
    font-family: 'Gotham';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    cursor: pointer;
}

.secondary-button {
    background: #eeeaf3;
    color: $primary200;
}

.cancel-button {
    @extend .button-basic;
    color: $error300;
    font-size: 12px;
}

.cancel-button-outlined {
    @extend .button-basic;
    background-color: $neutral0;
    border: 1px solid $neutral100;
    color: $neutral600;
}

.button-basic-primary {
    @extend .button-basic;
    background-color: $primary300;
    color: $neutral0;
    font-size: 12px;
    // width: 100%;

    &:disabled {
        background-color: $neutral100;
        color: #fff;
    }

    &.disabled {
        background-color: $neutral100;
        color: #fff;
    }
}

.headline1 {
    font-size: 46px;
    font-weight: 900;
    line-height: 56px;
}

.headline2 {
    font-size: 36px;
    font-weight: 900;
    line-height: 40px;
}

.headline3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

.headline4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.subtitle1 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.subtitle2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.body1 {
    @extend .subtitle2;
    font-weight: 400;
}

.body2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.button {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}

.caption {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.overline {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
}

.store-headline {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
}

.store-body {
    font-size: 30;
    font-weight: 400;
    line-height: 36px;
}

.semibold {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.card-stylized {
    display: flex;
    flex-wrap: wrap;

    .mat-card {
        min-width: unset !important;
        max-width: unset !important;
        width: 100% !important;
        box-shadow:
            0px 1px 8px 0px rgba(0, 0, 0, 0.08),
            0px 2px 4px 0px rgba(0, 0, 0, 0.12) !important;
        border-radius: 8px;
        margin: 0;

        .mat-card-header {
            padding: 12px 16px !important;
        }

        .mat-card-header {
            padding-left: 24px;
            background-color: #dedede;
            border-radius: unset !important;

            .mat-card-header-text {
                margin: unset !important;
            }
        }

        .mat-card-content {
            padding: 16px;
            margin-bottom: 0px;

            .description {
                margin-bottom: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 50px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
}

.prefix-icon {
    position: relative;
    top: -7px;
    margin-right: 8px;
    color: var(--neutral-400, var(--gray-02, #525252));
    text-align: center;
    font-family: Font Awesome 5 Free;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
}

.f-s-14 {
    font-size: 14px !important;
}

// INPUTS

input[type='radio']:after {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #000;
}

input[type='radio']:checked:after {
    width: 7px;
    height: 7px;
    border-radius: 15px;
    top: -4px;
    left: -3px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 6px solid $primary300;
}

.mat-dialog-container {
    overflow: visible !important;
}

// toggle
.toggle-class {
    .mainBorder {
        display: flex;
        border-radius: 8px;
        border: 1px solid var(--primary-300, #ffffff);
        background: var(--common-bg, #fff);
        margin-bottom: 20px;
        overflow: hidden;
    }

    .first-btn {
        border-radius: 7px;
        background-color: $primary100;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        cursor: pointer;
        color: #272115;
        font-size: 12px;
    }

    .first-btn-disable {
        @extend .first-btn;
        border-radius: unset;
        background-color: #fff;
        color: black;
        font-size: 12px;
    }
}

// toggle

// upload container
.upload-container {
    .box-dragdrop {
        border: 1px dashed #7a7a7a;
        border-radius: 8px;
        height: 260px;
        padding: 40px;
        position: relative;

        .box-dragdrop.isdragging {
            background-color: rgba($color: #cccccc, $alpha: 0.2);
            cursor: 'copy';
        }

        .dragdrop-info {
            height: 20px;
        }

        .dragdrop-icon {
            font-size: 48px;
            line-height: 48px;
            font-weight: 900;
            color: $primary300;
            margin-bottom: 16px;
        }

        .dragdrop-divider {
            margin: 16px 0;
            color: #7a7a7a;
        }

        .btn-upload {
            width: 202px;
            background: $primary300;
            font-weight: 700;
            color: $overPrimary;
        }
    }
}

// upload container

// align  class of fontawesome <i> with matPrefix of matInput
// ex : <i matPrefix class="fas fa-calendar m-r-10 align-matprefix-awesome"></i>
.align-matprefix-awesome {
    position: inherit;
    top: -7px !important;
    font-size: 20px;
}

// align  class of fontawesome <i> with matPrefix of matInput

.table-container {
    .table {
        width: 100%;

        tr {
            height: 48px;
        }

        th {
            background: var(--neutral-50, #dedede);
            color: var(--neutral-400, var(--gray-02, --primary100));
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }

        tr:hover {
            background-color: $neutral50;
        }

        .table-actions {
            display: flex;
            gap: 12px;
            align-items: center;
            justify-content: center;

            .action {
                cursor: pointer;
                border-radius: 200px;
                border: 1px solid var(--neutral-100, #ccc);
                background: var(--neutral-0, #fff);
                display: flex;
                padding: 8px;
                flex-direction: column;
                align-items: center;
                gap: 10px;
            }
        }
    }
}

.table-container-seccondary {
    .table {
        th {
            background: $neutral75;
            color: $primary100;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }

        td {
            border: none;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $primary100;
        }
    }
}

.icon-change {
    border-radius: 50%;
    background: #dadbda;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary300 !important;
    text-align: center;

    i {
        color: $primary300 !important;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
    }
}

.title-up {
    color: $primary200;
}

.gotham-l {
    font-family: 'Gotham light';
}
