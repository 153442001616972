@font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/GothamMedium2.ttf') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: 'Gotham Bold';
  src: url('/assets/fonts/GothamBold2.ttf') format("truetype");
  font-weight: bold;
}


@font-face {
  font-family: 'Gotham Light';
  src: url('/assets/fonts/GothamLight2.ttf') format("truetype");
  font-weight: 300;
}



