// Variables
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

$bodyfont: 'Lato', sans-serif;
$headingfont: 'Lato', sans-serif;

@import '~@angular/material/theming';

/*Material Theme Colors*/

$primaryM: mat-palette($mat-blue, 600);
$accent: mat-palette($mat-indigo, 500);
$warn: mat-palette($mat-pink, 500);
$theme: mat-light-theme($primaryM, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

// Default Theme
@include angular-material-theme($theme);

// Dark Theme

/*Theme Colors*/

$topbar: #2196f3;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: white;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #ffffff;
$sidebar-text-selected: var(--primary300, #ffffff);;
$sidebar-icons: var(--primary300, #ffffff);

$font-16: 16px;

$light-text: #a6b7bf;
$themecolor: #1e88e5;
$themecolor-alt: #fffcc9;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primaryM: #7460ee;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 200px;
$backgroundcolor: var(--backgroundcolor, '#ffffff');

// neutral
$neutral0: var(--neutral0, '#ffffff');
$overPrimary: $neutral0;
$neutral50: var(--neutral50, #ffffff);
$neutral75: var(--neutral75, #ffffff);
$neutral100: var(--neutral100, #ffffff);
$neutral200: var(--neutral200, #ffffff);
$neutral300: var(--neutral300, #ffffff);
$neutral400: var(--neutral400, #ffffff);
$neutral500: var(--neutral500, #ffffff);
$neutral600: var(--neutral600, #ffffff);
// neutral

// primary
$primary: var(--primary, #ffffff);
$primary50: var(--primary50, #ffffff);
$primary100: var(--primary100, #ffffff);
$primary200: var(--primary200, #ffffff);
$primary300: var(--primary300, #ffffff);
$primary400: var(--primary400, #ffffff);
$primary500: var(--primary500, #ffffff);
$primary600: var(--primary600, #ffffff);
// primary

// status
$success50: var(--success50, #ffffff);
$success300: var(--success300, #ffffff);

$error50: var(--error50, #ffffff);
$error300: var(--error300, #ffffff);

$alert50: var(--alert50, #ffffff);
$alert300: var(--alert300, #ffffff);

$information50: var(--information50, #ffffff);
$information100: var(--information100, #ffffff);
// status

// donuts
$donuts-positive-1: var(--donuts-positive-1, #ffffff);
$donuts-positive-2: var(--donuts-positive-2, #ffffff);
$donuts-positive-3: var(--donuts-positive-3, #ffffff);
$donuts-negative-1: var(--donuts-negative-1, #ffffff);
$donuts-negative-2: var(--donuts-negative-2, #ffffff);
$donuts-negative-3: var(--donuts-negative-3, #ffffff);
$donuts-negative-4: var(--donuts-negative-4, #ffffff);
$donuts-negative-5: var(--donuts-negative-5, #ffffff);
$donuts-negative-6: var(--donuts-negative-6, #ffffff);
// donuts

// miscellaneous
$miscellaneous-red: var(--miscellaneous-red, #ffffff);
$miscellaneous-pink: var(--miscellaneous-pink, #ffffff);
$miscellaneous-grape: var(--miscellaneous-grape, #ffffff);
$miscellaneous-violet: var(--miscellaneous-violet, #ffffff);
$miscellaneous-indigo: var(--miscellaneous-indigo, #ffffff);
$miscellaneous-blue: var(--miscellaneous-blue, #ffffff);
$miscellaneous-cyan: var(--miscellaneous-cyan, #ffffff);
$miscellaneous-teal: var(--miscellaneous-teal, #ffffff);
$miscellaneous-green: var(--miscellaneous-green, #ffffff);
$miscellaneous-lime: var(--miscellaneous-lime, #ffffff);
$miscellaneous-yellow: var(--miscellaneous-yellow, #ffffff);
$miscellaneous-orange: var(--miscellaneous-orange, #ffffff);
// miscellaneous

// asa variables
$gray09: #ececec;
