.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.m-4 {
  margin: 0.25rem;
}

.m-y-4 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.m-x-4 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.m-r-4 {
  margin-right: 0.25rem;
}

.m-l-4 {
  margin-left: 0.25rem;
}

.m-b-4 {
  margin-bottom: 0.25rem;
}

.m-t-4 {
  margin-top: 0.25rem;
}

.p-4 {
  padding: 0.25rem;
}

.p-y-4 {
  padding: 0.25rem;
  padding-bottom: 0.25rem;
}

.p-x-4 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.p-r-4 {
  padding: 0.25rem;
}

.p-l-4 {
  padding-left: 0.25rem;
}

.p-b-4 {
  padding-bottom: 0.25rem;
}

.p-t-4 {
  padding-top: 0.25rem;
}
// separados

.m-8 {
  margin: 0.5rem;
}

.m-y-8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.m-x-8 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.m-r-8 {
  margin-right: 0.5rem;
}

.m-l-8 {
  margin-left: 0.5rem;
}

.m-b-8 {
  margin-bottom: 0.5rem;
}

.m-t-8 {
  margin-top: 0.5rem;
}

.p-8 {
  padding: 0.5rem;
}

.p-y-8 {
  padding: 0.5rem;
  padding-bottom: 0.5rem;
}

.p-x-8 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-r-8 {
  padding: 0.5rem;
}

.p-l-8 {
  padding-left: 0.5rem;
}

.p-b-8 {
  padding-bottom: 0.5rem;
}

.p-t-8 {
  padding-top: 0.5rem;
}

// separados

.m-12 {
  margin: 0.75rem;
}

.m-y-12 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.m-x-12 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.m-r-12 {
  margin-right: 0.75rem;
}

.m-l-12 {
  margin-left: 0.75rem;
}

.m-b-12 {
  margin-bottom: 0.75rem;
}

.m-t-12 {
  margin-top: 0.75rem;
}

.p-12 {
  padding: 0.75rem;
}

.p-y-12 {
  padding: 0.75rem;
  padding-bottom: 0.75rem;
}

.p-x-12 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.p-r-12 {
  padding: 0.75rem;
}

.p-l-12 {
  padding-left: 0.75rem;
}

.p-b-12 {
  padding-bottom: 0.75rem;
}

.p-t-12 {
  padding-top: 0.75rem;
}

// separados

.m-16 {
  margin: 1rem;
}

.m-y-16 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.m-x-16 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.m-r-16 {
  margin-right: 1rem;
}

.m-l-16 {
  margin-left: 1rem;
}

.m-b-16 {
  margin-bottom: 1rem;
}

.m-t-16 {
  margin-top: 1rem;
}

.p-16 {
  padding: 1rem;
}

.p-y-16 {
  padding: 1rem;
  padding-bottom: 1rem;
}

.p-x-16 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.p-r-16 {
  padding: 1rem;
}

.p-l-16 {
  padding-left: 1rem;
}

.p-b-16 {
  padding-bottom: 1rem;
}

.p-t-16 {
  padding-top: 1rem;
}

// separados

.m-24 {
  margin: 1.5rem;
}

.m-y-24 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.m-x-24 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.m-r-24 {
  margin-right: 1.5rem;
}

.m-l-24 {
  margin-left: 1.5rem;
}

.m-b-24 {
  margin-bottom: 1.5rem;
}

.m-t-24 {
  margin-top: 1.5rem;
}

.p-24 {
  padding: 1.5rem;
}

.p-y-24 {
  padding: 1.5rem;
  padding-bottom: 1.5rem;
}

.p-x-24 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.p-r-24 {
  padding: 1.5rem;
}

.p-l-24 {
  padding-left: 1.5rem;
}

.p-b-24 {
  padding-bottom: 1.5rem;
}

.p-t-24 {
  padding-top: 1.5rem;
}

// separados

.m-32 {
  margin: 2rem;
}

.m-y-32 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.m-x-32 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.m-r-32 {
  margin-right: 2rem;
}

.m-l-32 {
  margin-left: 2rem;
}

.m-b-32 {
  margin-bottom: 2rem;
}

.m-t-32 {
  margin-top: 2rem;
}

.p-32 {
  padding: 2rem;
}

.p-y-32 {
  padding: 2rem;
  padding-bottom: 2rem;
}

.p-x-32 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.p-r-32 {
  padding: 2rem;
}

.p-l-32 {
  padding-left: 2rem;
}

.p-b-32 {
  padding-bottom: 2rem;
}

.p-t-32 {
  padding-top: 2rem;
}

// separados

.m-36 {
  margin: 2.25rem;
}

.m-y-36 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.m-x-36 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.m-r-36 {
  margin-right: 2.25rem;
}

.m-l-36 {
  margin-left: 2.25rem;
}

.m-b-36 {
  margin-bottom: 2.25rem;
}

.m-t-36 {
  margin-top: 2.25rem;
}

.p-36 {
  padding: 2.25rem;
}

.p-y-36 {
  padding: 2.25rem;
  padding-bottom: 2.25rem;
}

.p-x-36 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.p-r-36 {
  padding: 2.25rem;
}

.p-l-36 {
  padding-left: 2.25rem;
}

.p-b-36 {
  padding-bottom: 2.25rem;
}

.p-t-36 {
  padding-top: 2.25rem;
}

// separados

.m-40 {
  margin: 2.5rem;
}

.m-y-40 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.m-x-40 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.m-r-40 {
  margin-right: 2.5rem;
}

.m-l-40 {
  margin-left: 2.5rem;
}

.m-b-40 {
  margin-bottom: 2.5rem;
}

.m-t-40 {
  margin-top: 2.5rem;
}

.p-40 {
  padding: 2.5rem;
}

.p-y-40 {
  padding: 2.5rem;
  padding-bottom: 2.5rem;
}

.p-x-40 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.p-r-40 {
  padding: 2.5rem;
}

.p-l-40 {
  padding-left: 2.5rem;
}

.p-b-40 {
  padding-bottom: 2.5rem;
}

.p-t-40 {
  padding-top: 2.5rem;
}

// separados

.m-48 {
  margin: 3rem;
}

.m-y-48 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.m-x-48 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.m-r-48 {
  margin-right: 3rem;
}

.m-l-48 {
  margin-left: 3rem;
}

.m-b-48 {
  margin-bottom: 3rem;
}

.m-t-48 {
  margin-top: 3rem;
}

.p-48 {
  padding: 3rem;
}

.p-y-48 {
  padding: 3rem;
  padding-bottom: 3rem;
}

.p-x-48 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.p-r-48 {
  padding: 3rem;
}

.p-l-48 {
  padding-left: 3rem;
}

.p-b-48 {
  padding-bottom: 3rem;
}

.p-t-48 {
  padding-top: 3rem;
}